import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Node-RED Event Timeline for your MQTT Camera",
  "path": "/Advanced_User/Node-RED_Alarm_Event_Timeline/",
  "dateChanged": "2020-06-27",
  "author": "Mike Polinowski",
  "excerpt": "Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport) Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Node-RED Event Timeline for your MQTT Camera' dateChanged='2020-06-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport) Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_Alarm_Event_Timeline/' locationFR='/fr/Advanced_User/Node-RED_Alarm_Event_Timeline/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "node-red-event-timeline-for-your-mqtt-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-event-timeline-for-your-mqtt-camera",
        "aria-label": "node red event timeline for your mqtt camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Event Timeline for your MQTT Camera`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#use-case"
        }}>{`Use Case`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#used-nodes"
        }}>{`Used Nodes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-flow"
        }}>{`Node-RED Flow`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "use-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-case",
        "aria-label": "use case permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use Case`}</h2>
    <p>{`You are sitting in front of screen having to keep an eye on what is happening somewhere with your MQTT camera. You want to be able to see the live video from your camera, get a snapshot whenever the motion detection is triggered and have a event timeline so you can scroll back a few events and see what you have missed while fetching a coffee.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/e72cb51c012b988aac495a6b20008584/Node-RED_Event_Timeline_00.gif",
        "alt": "Node-RED Alarm Event Timeline"
      }}></img></p>
    <h2 {...{
      "id": "used-nodes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#used-nodes",
        "aria-label": "used nodes permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Used Nodes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-ui-state-trail"
        }}>{`node-red-contrib-ui-state-trail`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-onvif"
        }}>{`node-red-contrib-onvif`}</a>{` (Optional - there are `}<a parentName="li" {...{
          "href": "https://wiki.instar.com/Advanced_User/Node-RED_Dashboard_Live_Video/"
        }}>{`other ways to embed the live video`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-moment"
        }}>{`node-red-contrib-moment`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-alasql"
        }}>{`node-red-contrib-alasql`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`node-red-node-base64`}</a></li>
    </ul>
    <h2 {...{
      "id": "node-red-flow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-flow",
        "aria-label": "node red flow permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Flow`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "767px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/828e49edf866117dd027109bc8025843/6c2f2/Node-RED_Event_Timeline_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACU0lEQVQoz41SSW/TQBj1f+eCVAQHuIBUqUUVhx7gwFoRQgs0aZOaNGuzuNm9xPbYY08849lRYpaWA+VpZjSXN/Pe957BOV+jNE3gGqUJhDBZk1xIreV/wCAkbw1mn2udj9VWazif9sv24GW8LFGaSanuIDPGUZKCEIRhlGEiMNDE1TTQWqk7yZyx6sj9OnQaC+Ah1g/io+tlabz8NJmvc6qVEv8ic14e+MfjuLpIrRCfzxb7zc6LVvewN9iSpZAbuhBic2wvBQrZTNCMY5RnCc+xZFTlROFMYQxjSBnTWkul9cZGsQtsHBkpRFf1fuesN2xYIIwcOx72bceBfjidTk/m829J4q3GJav2BAxffRhY909rD6v1xxfNOMNGRojn+XEQY4TjKPZcHyFMCKMUZxlYr0GeY4z8FIzJ2q+Z7ypnzyrn+43LvSRsGUopIQTjknGd55wQrG9DSqWULuTGSZBBF0OfYyA5NoQQQQhWXs9elFFiSaUvfXi8CEzPTxHinN8cr/rlWSktlTKklL4fjK6+tM09q19ZzkfPT3bvvXl62GwDEDHGbpKLyf+JqogBRrnrkNDnCZxH9nsSVrTg+q6SbmRTShmXQmrOOdtmsxGmlVI3//y5/qoni0DkOb3F7NRfTcIwgTFKUpamLEOccX0rXX3rSYNz5nn+xHrdbTyYXb91bGfUGVnmhdXsXjXb9viSotkqgabtfrcd03YjjH931lBKUsq8ZWlqHcysXQDaWWizyTFf9brdo1HzgLhlcz7ZqdQfnZ7vVOrDMNJa8209fwBhBoDaInOEXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/828e49edf866117dd027109bc8025843/e4706/Node-RED_Event_Timeline_01.avif 230w", "/en/static/828e49edf866117dd027109bc8025843/d1af7/Node-RED_Event_Timeline_01.avif 460w", "/en/static/828e49edf866117dd027109bc8025843/b2575/Node-RED_Event_Timeline_01.avif 767w"],
              "sizes": "(max-width: 767px) 100vw, 767px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/828e49edf866117dd027109bc8025843/a0b58/Node-RED_Event_Timeline_01.webp 230w", "/en/static/828e49edf866117dd027109bc8025843/bc10c/Node-RED_Event_Timeline_01.webp 460w", "/en/static/828e49edf866117dd027109bc8025843/e0ad8/Node-RED_Event_Timeline_01.webp 767w"],
              "sizes": "(max-width: 767px) 100vw, 767px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/828e49edf866117dd027109bc8025843/81c8e/Node-RED_Event_Timeline_01.png 230w", "/en/static/828e49edf866117dd027109bc8025843/08a84/Node-RED_Event_Timeline_01.png 460w", "/en/static/828e49edf866117dd027109bc8025843/6c2f2/Node-RED_Event_Timeline_01.png 767w"],
              "sizes": "(max-width: 767px) 100vw, 767px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/828e49edf866117dd027109bc8025843/6c2f2/Node-RED_Event_Timeline_01.png",
              "alt": "Node-RED Alarm Event Timeline",
              "title": "Node-RED Alarm Event Timeline",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/nodered_flow_node-red_alarm_event_timeline.json"
        }}>{`Download JSON Flow`}</a></li>
    </ul>
    <h3 {...{
      "id": "walkthrough",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#walkthrough",
        "aria-label": "walkthrough permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Walkthrough`}</h3>
    <ol>
      <li parentName="ol">{`Create a database in AlaSQL (I called mine `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`AlarmSnapDB`}</code>{`). This node is triggered with every restart of Node-RED to make sure that it is ready for use.`}</li>
      <li parentName="ol">{`Fetch the live video (I am using ONVIF you can use `}<a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_Dashboard_Live_Video/"
        }}>{`another way to embed the live video`}</a>{`)`}</li>
      <li parentName="ol">{`Now I need an MQTT IN node that is listening to my cameras alarmserver `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/status/alarm/triggered`}</code>{` (see `}<a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/"
        }}>{`this article`}</a>{` for an explanation how those topics work)`}</li>
      <li parentName="ol">{`The alarm server node triggers Node-RED to fetch a snapshot from my camera, base64 it and stores it into the database. Additionally, I display the snapshot on my dashboard. I added a trigger node that makes sure that there is only one image per alarm event - you might want to remove that or adjust the `}<strong parentName="li">{`8 second`}</strong>{` cool down that I found works perfectly for my use case.`}</li>
      <li parentName="ol">{`The alarm server node also triggers the `}<strong parentName="li">{`Timeline`}</strong>{` to register an event.`}</li>
      <li parentName="ol">{`Node-RED receive the timestamp of an event when you click on it in the timeline dashboard widget. I use this timestamp to search for the corresponding event in my database and display the corresponding image on my dashboard. `}<strong parentName="li">{`Note`}</strong>{` that the timestamp format used inside the database and the one used by the timeline widget is different. To be able to compare them I am using the `}<strong parentName="li">{`Moment.js`}</strong>{` - you will have to configure this one according to your system timezone settings!`}</li>
    </ol>
    <h2 {...{
      "id": "node-red-dashboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-dashboard",
        "aria-label": "node red dashboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Dashboard`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df8c1f27389b12e2529efa29f4b1773a/a7a19/Node-RED_Event_Timeline_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACjUlEQVQoz32QXU/aYBiG+R8ajzRhlJf2fVugILRAX4qlWFor/eSzMDAxxjGX6cYQkWRmBx544A9w26keG8M23BKOTIz/aAG3ZFmi19GdPLlyP7l9evzIzZyUVkfr9Ac1PFDDg2L4UKH7ReZQDQ/MxEedHUqhgzx5oDB9LXo0uzJ9LXKkRQa+YMgvZFOKmsdiio0zST5Wa7heq1rU5HgiUtwoGNbG/rs3J59GBUUqVy23Yq4XJSZCESG/D1JoUy+9bLVty1GLmmO7PJ8WMjgWW6Uo9OXz12/j7z9vf01+3PZ6/e6r102vRdPhwAsCUsgHALBte3d31zRNy7La7TbLsgCApaUlAMDNzc3Dw8N4PL64uDg9Pb26ujo+Pl5cXCRJEkLoI0kyl8vV6/VSqSRJkm3bsiwnEgmE0MLCwnA4nE6nd3d3k8lkNBpdXl62Wi2/37+ysoIQ8iGE1tbWHMeRJAlCKMuy53mmaW5vb9M0vbe3d39/P51Oz87Oer3e9fX1zs5OpVKhaZokyVmzIAiFQiEWiyGEWJbFGHMcx/N8NBoVBMEwDEVRMMaSJKmqynFcMplkGOaPLOZETVNlWeZ5HmNcrVZrtZqmaSzLEgQRCAQIggAAEHNCoRAAgJozkw3D6HQ6lmXpuu66Lsa40WhACJeXlyGEaA6c8xiov8zWtiyr2+0ahmFZlud5iqI0m814PL61tfX4HvUEPoqiMMau6+q6LmQE0zRZNlYul8/Pz/ff7geDwX+r/pdna0s5x3FwNktSAOPsaiJeb9Te9w+wKBAB4jl5Nlgqv7mhi6IocrIs57N8PpcqaIIjpzUShqinmcmZpJhOpXmOz/EFjudEPp9NSji6nk3IFCKfkX8DsGX3E0XLCWAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df8c1f27389b12e2529efa29f4b1773a/e4706/Node-RED_Event_Timeline_02.avif 230w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/d1af7/Node-RED_Event_Timeline_02.avif 460w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/7f308/Node-RED_Event_Timeline_02.avif 920w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/5f902/Node-RED_Event_Timeline_02.avif 1323w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df8c1f27389b12e2529efa29f4b1773a/a0b58/Node-RED_Event_Timeline_02.webp 230w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/bc10c/Node-RED_Event_Timeline_02.webp 460w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/966d8/Node-RED_Event_Timeline_02.webp 920w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/41c4d/Node-RED_Event_Timeline_02.webp 1323w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df8c1f27389b12e2529efa29f4b1773a/81c8e/Node-RED_Event_Timeline_02.png 230w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/08a84/Node-RED_Event_Timeline_02.png 460w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/c0255/Node-RED_Event_Timeline_02.png 920w", "/en/static/df8c1f27389b12e2529efa29f4b1773a/a7a19/Node-RED_Event_Timeline_02.png 1323w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df8c1f27389b12e2529efa29f4b1773a/c0255/Node-RED_Event_Timeline_02.png",
              "alt": "Node-RED Alarm Event Timeline",
              "title": "Node-RED Alarm Event Timeline",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Top left is the live video from my camera`}</li>
      <li parentName="ol">{`Top right is the snapshot from the latest alarm event`}</li>
      <li parentName="ol">{`Bottom left - clicking on an event inside the timeline widget loads the corresponding alarm snapshot.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      